<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Štítky - seřadit</strong>
      </div>
      <action-tools :routerOptions="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form class="login-form" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-tree
            @drop="handleDrop"
            draggable
            :treeData="treeData"
            class="draggable-tree"
          >
          </a-tree>
          <a-empty v-if="treeData.length === 0" />
        </a-form-item>

        <div class="form-actions">
          <a-button
            :disabled="loading"
            type="primary"
            htmlType="submit"
          >Seřadit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      form: this.$form.createForm(this),
      treeData: [],
      loading: false,
      loaded: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/ecommerce/sticker/add',
          title: 'Přidat',
        },
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/sticker',
          title: 'Seznam',
        },
      ],
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
    sticker: function () {
      return this.$store.getters['sticker/currentLanguage']('')
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.loading = true
      this.$store.dispatch('sticker/putSort', this.treeData.map(x => x.key))
        .then(() => {
          this.handleRefresh()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    toTreeNodeData(data) {
      return data.map(x => {
        return {
          key: x.ser_id,
          title: '#' + x.ser_id + ' | ' + (x.languages[0] !== undefined ? x.languages[0].pivot.label : 'není nenastaveno'),
          selectable: false,
        }
      })
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('sticker/getList')
        .catch(() => {})
        .finally(() => {
          this.treeData = this.toTreeNodeData(this.sticker)
          this.loaded.push(this.language)
          this.loading = false
        })
    },
    loop(data, key, callback) {
      return data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(item, index, arr)
        }
      })
    },
    handleDrop(info) {
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey

      let dragObj
      let spliceObjArr
      let spliceObjIndex
      let shouldSplice = true
      this.loop(this.treeData, dragKey, (item, index, arr) => {
        spliceObjArr = arr
        spliceObjIndex = index
        dragObj = item
      })
      if (!info.dropToGap) {
        return false
      }
      let ar
      let i
      this.loop(this.treeData, dropKey, (item, index, arr) => {
        ar = arr
        i = index
      })
      spliceObjArr.splice(spliceObjIndex, 1)
      shouldSplice = false
      ar.splice(i, 0, dragObj)
      if (shouldSplice) {
        spliceObjArr.splice(spliceObjIndex, 1)
      }
    },
  },
  created() {
    if (this.treeData.length === 0 && this.sticker.length !== 0) {
      this.treeData = this.toTreeNodeData(this.sticker)
    }
    if (this.sticker.length === 0 && this.$store.getters['language/active'] !== null) {
      this.handleRefresh()
    }
  },
  watch: {
    sticker() {
      this.treeData = this.toTreeNodeData(this.sticker)
    },
    language(newValue, oldValue) {
      if (newValue !== null && !this.loaded.includes(this.language)) {
        this.handleRefresh()
      }
    },
  },
}
</script>

<style lang="scss" module scoped>

</style>
